<template>
  <v-container fluid>
    <template v-if="loggedUser.externalTier == null">
      <v-row no-gutters :align="'center'" :justify="'center'" v-if="$vuetify.breakpoint.smAndUp">
      <h1 class="mr-3">{{ $t('plan.pricingPlansTitle') }}</h1>
      <h1>{{ taxYear }}</h1>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="auto" left  v-if="availableTaxYears.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-3" v-bind="attrs" v-on="on" x-small fab
            color="accent"><v-icon>mdi-chevron-down</v-icon></v-btn>
        </template>
        <v-date-picker v-model="picker" @click:year="saveYear" ref="picker"
          :max="new Date().toISOString().replace(currentYear, availableTaxYears.slice(0))"
          :min="new Date().toISOString().replace(currentYear, availableTaxYears.slice(-1))" no-title scrollable>
        </v-date-picker>
      </v-menu>
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row no-gutters :align="'center'" :justify="'center'">
        <v-col :align="'center'" :justify="'center'">
          <h1>{{ $t('plan.pricingPlansTitle') }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters :align="'center'" :justify="'center'">
      <h1>{{ taxYear }}</h1>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
        min-width="auto" left v-if="availableTaxYears.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-3" v-bind="attrs" v-on="on" x-small fab
            color="accent"><v-icon>mdi-chevron-down</v-icon></v-btn>
        </template>
        <v-date-picker v-model="picker" @click:year="saveYear" ref="picker"
          :max="new Date().toISOString().replace(currentYear, availableTaxYears.slice(0))"
          :min="new Date().toISOString().replace(currentYear, availableTaxYears.slice(-1))" no-title scrollable>
        </v-date-picker>
      </v-menu>
    </v-row>
    </template>
    <v-row :align="'center'" :justify="'center'">
      <v-col :align="'center'" :justify="'center'">
        <p v-html="$t('plan.pricingPlansDescription', {taxYear: taxYear, transactions: taxYearTransactions})"></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert v-if="loggedUser.userRole !== 'USER'" type="info">{{ $t('message.superUser') }}</v-alert>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row justify="center">
      <v-col cols="12" md="3" sm="6" class="d-flex">
        <pricing-card :pricingPlanType="'FREE'" :taxYear="taxYear" class="w-100"></pricing-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" class="d-flex">
        <pricing-card :pricingPlanType="'BASE'" :taxYear="taxYear" class="w-100"></pricing-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" class="d-flex">
        <pricing-card :pricingPlanType="'STANDARD'" :taxYear="taxYear" class="w-100"></pricing-card>
      </v-col>
      <v-col cols="12" md="3" sm="6" class="d-flex">
        <pricing-card :pricingPlanType="'PREMIUM'" :taxYear="taxYear" class="w-100"></pricing-card>
      </v-col>
    </v-row>
    </template>
    <template v-else>
      <v-row no-gutters :align="'center'" :justify="'center'">
      <h1>{{ $t('plan.pricingPlansTitle') }}</h1>
    </v-row>
      <v-row :align="'center'" :justify="'center'">
      <v-col :align="'center'" :justify="'center'">
        <p v-html="$t('plan.pricingPlansDescriptionYoung', {tier: $options.filters.ExternalTierFormatter(loggedUser.externalTier)})"></p>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row justify="center">    
      <v-col cols="12" md="4" sm="6" class="d-flex">
        <young-pricing-card :pricingPlanType="'YOUNG_PLATFORM'" :tier="loggedUser.externalTier" class="w-100"></young-pricing-card>
      </v-col>
    </v-row>
    </template>
    <v-row>
      <v-col cols="12">
        <v-card class="info-top-color" v-if="getPricingPlanType != 'ENTERPRISE'">
          <v-card-title>
            {{ $t('plan.customSolutionTitle') }}
          </v-card-title>
          <v-card-text>
            <v-row :align="'center'" :justify="'center'">
              <v-col cols="auto">
                <p>{{ $t('plan.customSolutionDescription') }}</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="accent" tile @click="sendEmail">{{ $t('plan.contactUs') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-alert type="info" v-else>{{ $t('plan.customPlanActivated', {transactions: loggedUser.pricingPlan.transactionLimit, taxYear: new Date().getFullYear() - 1}) }}</v-alert>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('plan.stripeTitle') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="2" v-if="!$vuetify.breakpoint.xs" :align="'center'" :justify="'center'">
                <v-img :src="require(`../../assets/plan/STRIPE.svg`)" max-width="150"></v-img>
              </v-col>
              <v-col>
                {{ $t('plan.stripeDescription') }}
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs">
              <v-col :align="'center'" :justify="'center'">
                <v-img :src="require(`../../assets/plan/STRIPE.svg`)" max-width="150"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PricingCard from '../../components/card/PricingCard.vue';
import YoungPricingCard from '../../components/card/YoungPricingCard.vue';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
export default {
  name: "pricing-plans-page",
  components: {
    PricingCard, YoungPricingCard
  },
  data() {
    return {
      loading: false,
      menu: false,
      picker: null,
      currentYear: new Date().getFullYear(),
      taxYear: new Date().getFullYear() - 1
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("tax", ["availableTaxYears", "taxYearTransactions"]),
    getSubject(){
      return this.$t('message.contactUsSubject')
    },
    getPricingPlanType(){
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(x => x.taxYear == this.taxYear);
      if (plan != null) {
        return plan.pricingPlanType;
      } else {
        return 'FREE';
      }
    }
  },
  methods: {
    ...mapActions("tax", ["fetchAvailableTaxYears", "fetchTaxYearTransactions"]),
    saveYear(year) {
      this.taxYear = year
      this.menu = false
    },
    sendEmail() {
      var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
      var a = document.createElement('a');
      a.href = mail;
      a.click();
    }
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    taxYear(val, oldVal) {
      if (val !== oldVal) {
        this.fetchTaxYearTransactions(this.taxYear)
      }
    }
  },
  created() {
    if (this.$route.params.taxYear) {
      this.taxYear = this.$route.params.taxYear
    }
    this.fetchAvailableTaxYears();
    this.fetchTaxYearTransactions(this.taxYear);
    
  },
}
</script>

<style scoped>
@media (max-width: 959.99px) {
  .d-flex {
    display: flex !important;
    justify-content: center;
  }
}
.info-top-color {
    border-top: solid 5px var(--v-accent-base) !important;
}
</style>