<template>
  <v-container fluid>
    <div :class="isCurrentPlan ? 'card-overlay' : ''">
      <v-card class="pricing-card">
        <v-card-title>
          <div class="pa-3">
            <v-img :src="require(`../../assets/plan/${pricingPlanType}_${loggedUser.settings.theme}.svg`)" width="200"
              aspect-ratio="1"></v-img>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <h1 class="primary--text my-3">{{ pricingPlanType | PricingPlanFormatter }}</h1>
          <span>{{ pricingPlanType | PricingPlanDescription }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-row v-for="(feature, i) in getFeatures" :key="i" no-gutters :align="'center'" :justify="'center'">
            <v-col cols="auto" :align="'center'" :justify="'center'">
              <p v-html="feature.feature"></p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="mt-5 mx-1">
            <div class="old-price-container">
              <h1 class="old-price mr-1" v-if="pricingPlanType != 'FREE'">{{ 229 | UnhideCurrencyFormatter(pricingPlanCurrency) }}</h1>
              <h1 class="primary--text">{{ getPrice | UnhideCurrencyFormatter(pricingPlanCurrency) }}</h1>
            </div>
            <p>{{ $t('plan.oneTimePurchase') }}</p>
          </div>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="px-3">
          <v-btn :disabled="isButtonDisabled" @click="submit" :loading="loading" color="accent" class="white--text" tile
            width="100%">{{ getButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
export default {
  name: "young-pricing-card",
  props: {
    pricingPlanType: {
      type: String,
      required: true
    },
    tier: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      taxYear: new Date().getFullYear() - 1,
      pricingPlanCurrency: "EUR",
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    getPrices() {
      return {
        'BASE': 59,
        'BRONZE': 54,
        'SILVER': 49,
        'GOLD': 44,
        'PLATINUM': 39,
      }
    },
    getTransactionLimits() {
      return {
        'FREE': 50,
        'BASE': 10000,
        'BRONZE': 10000,
        'SILVER': 10000,
        'GOLD': 10000,
        'PLATINUM': 10000,
        'YOUNG_PLATFORM': 10000,
      }
    },
    isCurrentPlan() {
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(x => x.taxYear == this.taxYear);
      if (plan != null) {
        return this.pricingPlanType == plan.pricingPlanType;
      } else {
        if (this.pricingPlanType == 'FREE') {
          return true;
        } else {
          return false;
        }
      }
    },
    getCurrentPlan() {
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(x => x.taxYear == this.taxYear);
      if (plan != null) {
        return plan.pricingPlanType;
      } else {
        return 'FREE';
      }
    },
    isButtonDisabled() {
      return this.isCurrentPlan || this.pricingPlanType == 'FREE' || !this.isPlanAvailable
    },
    showBadge() {
      return this.isCurrentPlan;
    },
    getBadgeContent() {
      return this.isCurrentPlan ? this.$t('plan.currentPlan') : this.$t('plan.mostPopular');
    },
    getPrice() {
      if (this.pricingPlanType == 'FREE') {
        return 0;
      } else {
        return this.getPrices[this.tier];
      }
    },
    getPricingPlanPeriodType() {
      return 'LIFETIME'
    },
    getButtonText() {
      if (this.isCurrentPlan) {
        return this.$t('plan.currentPlanButton');
      } else {
        return this.$t('plan.buyButton');
      }
  },
  isPlanAvailable() {
    return true//this.taxYearTransactions <= this.getTransactionLimits[this.pricingPlanType]
  },
  getFeatures() {
    if (this.pricingPlanType == 'FREE') {
      return [
        { feature: this.$t('plan.feature.connections'), active: true },
        { feature: this.$t('plan.feature.youngReports', { numTx: 50 }), active: true },
        { feature: this.$t('plan.feature.baseSupport'), active: true },
      ]
    } else {
      return [
        { feature: this.$t('plan.feature.connections'), active: true },
        { feature: this.$t('plan.feature.youngReports', { numTx: 10000 }), active: true },
        { feature: this.$t('plan.feature.premiumSupport'), active: true },
      ]
    }
  }
},
methods: {
    ...mapActions("payment", ["createCheckoutSession", "createPortalSession"]),
    submit() {
    this.loading = true;
    // You will be redirected to Stripe's secure checkout page
    const payload = {
      paymentProviderType: "STRIPE",
      pricingPlanPeriodType: this.getPricingPlanPeriodType,
      pricingPlanType: this.pricingPlanType,
    };
    this.createCheckoutSession(payload).then(response => {
      window.location.href = response.data;
    }).finally(() => {
      this.loading = false;
    });
  },
  getIcon(active) {
    if (active) {
      return 'mdi-check';
    } else {
      return 'mdi-close';
    }
  },
  getIconColor(active) {
    if (active) {
      return 'success';
    } else {
      return 'error';
    }
  },
}
}

</script>

<style scoped>
.v-card__title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-card__subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 16px;
}

.primary--text {
  color: #121212;
}

.v-icon {
  margin-right: 4px;
}

.pricing-card {
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  padding: 16px;
  text-align: center;
  transition: transform 0.3s;
}

.pricing-card:hover {
  transform: translateY(-6px);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
}

.pricing-card .v-img {
  margin: 0 auto;
  max-width: 100px;
  height: auto;
}


.pricing-card p {
  font-size: 14px;
  color: #707070;
}

.pricing-card .v-btn:hover {
  box-shadow: none;
  background-color: #e53935;
}

.pricing-card .v-btn:focus {
  box-shadow: none;
  background-color: #c62828;
}

@media (max-width: 959.99px) {
  .pricing-card {
    margin-bottom: 24px;
  }

  .pricing-card .v-card__title {
    margin-bottom: 16px;
  }

  .pricing-card .v-card__subtitle {
    margin-top: 0;
  }

  .pricing-card h1 {
    font-size: 24px;
  }

  .pricing-card p {
    font-size: 14px;
  }

  .pricing-card .v-btn {
    padding: 12px 24px;
    font-size: 14px;
  }
}
.old-price {
  text-decoration: line-through;
  font-size: 1em; /* Adjust the size as needed */
}
.old-price-container {
  display: flex;
  justify-content: center; /* Adjust alignment as needed */
  align-items: center; /* Adjust alignment as needed */
}
</style>